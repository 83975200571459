table{

	& .comment{
		font-size: 14px;
		color: #777;
	}

	& .values{
		display: flex;
	}

	& tr{
		cursor: pointer;
	}
}

.modal-wrapper{
	border: 1px solid red;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: rgba(#fff, .85);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity .4s;
	opacity: 0;
	pointer-events: none;
	
	& .modal{
		padding: 20px;
		width: 40vw;
		max-width: 500px;
		background-color: #fff;
		box-shadow: 0 4px 46px  rgba(12, 21, 97, 0.051);
		transition: transform .4s;
		transform: translateY(40px);

		& .modal-footer{
			display: flex;
			justify-content: space-between;
			margin-top: 20px;
		}
	}

	&.open{
		opacity: 1;
		pointer-events: all;

		& .modal{
			transform: none;
		}
	}
}

.save-wrapper{
	position: sticky;
	bottom: 0;
	background-color: rgba(#FAFCFF, .7);
	backdrop-filter: blur(10px);
	z-index: 2;
	padding: 20px;
	text-align: center;

	& button:not(:last-of-type){
		margin-right: 20px;
	}
}

.filters-wrapper{

	margin-top: 20px;
	
	& .filters-card-content{
		padding-bottom: 1em !important;

		& input{
			line-height: 2em !important;
			height: 2em !important;
			display: block;
		}
	}

	& .pair-setup{

		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-left: 40px;
	
		& .icon-block{
			margin-right: 20px;
		}
	
		& .buttons-wrapper{
	
			flex-grow: 1;
	
			& [role="group"]{
				width: 100%;
			}
	
			& button{
				flex-grow: 1;
			}
		}
	}
}