.progress-wrapper{
	min-width: 260px;
	display: flex;
	align-items: center;

	& .progressbar-wrapper{
		display: block;
		flex-grow: 1;
		flex-shrink: 0;
		margin-right: 10px;
	}
}