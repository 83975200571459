.kv-pair {
	display: table-row;
	padding-bottom: 15px;

	& .key,
	& .value {
		display: table-cell;
		padding-bottom: 10px;
	}

	& .key {
		font-size: 14px;
		padding-right: 20px;
		color: #939393;
		min-width: 150px;
	}

	@media (max-width: 310px) {
		display: block;
		margin-bottom: 10px;

		& .key,
		& .value {
			display: block;
			padding-bottom: 0;
		}
	}

	@media print {
		& .key,
		& .value {
			font-size: 10pt;
		}

		& .key {
			width: 20%;
		}
	}
}
