.login-screen{

  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  & h1{
    margin-top: 0;
  }

  & .field{
    margin-bottom: 20px;
    text-align: right;
  }
}