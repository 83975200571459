#value-modal {
	& .value-wrapper {
		margin-bottom: 20px;

		& .value-type {
			margin-bottom: 20px;
		}

		& .value {
			margin-bottom: 20px;
		}

		& .value-actions {
			& span {
				display: block;
			}

			display: flex;
			justify-content: space-between;
		}
	}

	& .reasons {
		&.hide {
			display: none;
		}
	}

	& .reason-block {
		margin-bottom: 20px;

		& .file-wrapper {
			display: flex;
			justify-content: space-between;
			min-height: 40px;

			& .clear-file {
				&.hide {
					display: none;
				}
			}
		}

		& .file-label {
			width: 100%;
			cursor: pointer;
			display: flex;
			align-items: center;

			& svg {
				margin-right: 8px;
			}
		}
	}
}
