@import url("https://fonts.googleapis.com/css2?family=Wix+Madefor+Text:ital,wght@0,400..800;1,400..800&display=swap");

body {
  font-family: "Wix Madefor Text", "Roboto Flex", "Roboto", sans-serif;
  font-size: clamp(16px, 3vw, 18px);

  @media print {
    font-size: 12pt !important;
  }

  & h1 {
    font-size: clamp(24px, 3vw, 32px);
    font-weight: 300;
    line-height: 1.4em;
  }

  & h2 {
    font-size: clamp(20px, 3vw, 24px);
    line-height: 1.4em;
    font-weight: 300;
  }
}
